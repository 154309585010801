.App {
  background-color: #0e141b;
  width: 100%;
  height: 100vh;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 400;
}

.header{
  height: 65px;
  color: white;
  font-weight: 500;
  font-size: 18px;
  background-color: #0e141b;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.header img{
  width: 50px;
  top: 2px;
  position: relative;
}

.content{
  background-color: #0e141b;
  height: 80%;
  margin: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.links{
  background-color: #0e141b;
  width: 90%;
}

.single_link{
  background-color: #0e1d29;
  color: white;
  border-radius: 45px;
  padding:10px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
}

.single_link p {
  margin-left: 5px;
}

.footer{
  background-color: #0f171f;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  font-size: 16px;
}



.wave-wrapper{
  background-color: #0e141b;
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.wave-wrapper svg{
  width: 100%;
  height: 100%;
}


a {
  transition: all .3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none; /* no underline */
}

a:hover{
  transition: all .3s;
  color: #5263f2;
}

.memoji{
  width: 100px;
  border-radius: 50px;
}


